import styles from "@styles/MobileTabs.module.css";
import { Tab } from "@lib/utils";
import { useRouter } from "next/router";

interface Props {
  tabs: Tab[];
}

export default function MobileTabs({ tabs }: Props) {
  const router = useRouter();

  return (
    <>
      <div className={styles.tabs}>
        {tabs.map((tab: Tab, idx: number) => {
          return tab.attributes.outsideLink == true ? (
            <a
              href={tab.attributes.link}
              target="_blank"
              rel="noreferrer"
              key={idx}
              aria-label={tab.attributes.text}
            >
              <div className={styles.tab}>{tab.attributes.text}</div>
            </a>
          ) : (
            <a
              className={`${styles.tab}${
                tab.attributes.link == router.pathname
                  ? " " + styles.active
                  : ""
              }`}
              key={idx}
              onClick={() => {
                router.push(tab.attributes.link);
              }}
              role="button"
              aria-label={tab.attributes.text}
            >
              {tab.attributes.text}
            </a>
          );
        })}
      </div>
    </>
  );
}
