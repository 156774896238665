import { Social, Tab } from "@lib/utils";
import styles from "@styles/Footer.module.css";
import Socials from "@components/Socials";
import Tabs from "@components/Tabs";

interface Props {
  socials: Social[];
  tabs: Tab[];
}

export default function Footer({ socials, tabs }: Props) {
  return (
    <>
      <footer
        className={styles.footer}
        itemScope
        itemType="https://schema.org/WPFooter"
      >
        <Tabs tabs={tabs} allowClick={true} isFooter={true} />
        <Socials isFooter={true} socials={socials} />
        <div className={styles.texts}>
          <p className={styles.text} itemProp="accountablePerson">
            © 2023 BOLAGET RECORDS. ALL RIGHTS RESERVED.
          </p>
          <p className={styles.text} itemProp="author">
            DESIGNED & DEVELOPED BY{" "}
            <a className={styles.link} href="https://www.zerio.se?ref=bolaget">
              Zerio AB
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
