import styles from "@styles/Navbar.module.css";
import { NextRouter, useRouter } from "next/router";
import Socials from "@components/Socials";
import Tabs from "@components/Tabs";
import MobileTabs from "@components/MobileTabs";
import { Social, Tab, WindowSize } from "@lib/utils";
import { useEffect, useState } from "react";
import Link from "next/link";

interface Props {
  header: string | null;
  size: WindowSize;
  customNum?: number;
  socials: Social[];
  tabs: Tab[];
}

export default function Navbar({
  header,
  size,
  customNum,
  socials,
  tabs,
}: Props) {
  const router: NextRouter = useRouter();
  const [toggled, setToggled] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [lastKnownScroll, setLastKnownScroll] = useState<number>(0);

  function toggleNav(): void {
    if (show == true) {
      setToggled(!toggled);
    }
  }

  useEffect(() => {
    document.onscroll = (e: Event) => {
      if (toggled) {
        e.preventDefault();
        window.scrollTo(0, lastKnownScroll);
        return false;
      }

      if (
        lastKnownScroll < window.scrollY &&
        window.scrollY > size.height / 4
      ) {
        // scroll down
        setShow(false);
      } else {
        // scroll up
        setShow(true);
      }

      setLastKnownScroll(window.scrollY);
    };
  }, [lastKnownScroll, toggled, show, size.height]);

  useEffect(() => {
    window.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.key == "Escape") {
        setToggled(false);
      }
    });
  }, []);

  useEffect(() => {
    if (size.width > 720 || size.width > size.height) {
      setToggled(false);
    }
  }, [size.width, size.height]);

  return (
    <>
      {size.width > 720 && size.width > size.height ? (
        <></>
      ) : (
        <nav
          className={`${styles.mobileNav}${toggled ? " " + styles.active : ""}`}
        >
          <MobileTabs tabs={tabs} />
        </nav>
      )}
      <nav
        className={`${styles.nav}${show ? " " + styles.allowClick : ""}${
          size.width > 720 && size.width > size.height
            ? " " + styles.zIndex
            : ""
        }${show ? " " + styles.show : ""}${
          lastKnownScroll >
          size.height / (customNum !== undefined ? customNum : 2)
            ? " " + styles.background
            : ""
        } ${size.width > 720 ? styles.large : styles.small}`}
      >
        <div className={styles.navInner}>
          <div className={styles.navLeft}>
            {size.width > 720 ? (
              size.width > 1450 && size.width > size.height ? (
                router.pathname !== "/" &&
                header !== null &&
                size.width > 720 ? (
                  <Tabs tabs={tabs} allowClick={show} isFooter={false} />
                ) : (
                  <></>
                )
              ) : (
                <Tabs tabs={tabs} allowClick={show} isFooter={false} />
              )
            ) : (
              <></>
            )}
          </div>
          <div className={styles.header}>
            {size.width > 1450 && size.width > size.height ? (
              router.pathname !== "/" && header !== null ? (
                <Link href="/">
                  <div
                    style={{
                      backgroundImage: "url(" + header + ")",
                    }}
                    className={styles.headerImage}
                  />
                </Link>
              ) : (
                <Tabs tabs={tabs} allowClick={show} isFooter={false} />
              )
            ) : (
              <></>
            )}
          </div>
          <div className={styles.navRight}>
            {size.width > 720 ? (
              <Socials isFooter={false} socials={socials} />
            ) : (
              <div
                className={`${styles.navToggle}${
                  toggled ? " " + styles.toggled : ""
                }`}
                onClick={toggleNav}
              >
                <div className={styles.lineHolder}>
                  <span className={styles.lineOne}></span>
                  <span className={styles.lineTwo}></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
