import styles from "@styles/tabs/index/HeroSection.module.css";
import Image from "next/image";
import Link from "next/link";

interface Props {
  image: string;
  icon: string;
  link: string;
  text: string;
}

export default function HeroSection({ icon, link, text, image }: Props) {
  return (
    <div className={styles.hero}>
      <Link
        href={link}
        target="_blank"
        rel="norefferer"
        className={styles.instagram}
      >
        <Image
          className={styles.instagramIcon}
          src={icon}
          width={24}
          height={24}
          alt="Instagram"
        />
        <p className={styles.instagramText}>{text}</p>
      </Link>

      <div
        className={styles.heroImage}
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
    </div>
  );
}
