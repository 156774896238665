import { type Social } from "@lib/utils";
import styles from "@styles/Socials.module.css";
import Image from "next/image";

interface Props {
  isFooter: boolean;
  socials: Social[];
}

export default function Socials({ isFooter, socials }: Props) {
  return (
    <>
      <div
        className={`${styles.socialList} ${
          isFooter ? styles.footer : styles.normal
        }`}
      >
        {socials.map((social: Social, idx: number) => {
          return (
            <a
              href={social.attributes.link}
              target="_blank"
              rel="noreferrer"
              key={idx}
              aria-label={social.attributes.name}
            >
              <Image
                className={styles.socialImg}
                width={isFooter ? 20 : 24}
                height={isFooter ? 20 : 24}
                alt={social.attributes.name}
                src={
                  process.env.NEXT_PUBLIC_STRAPI_API_URL +
                  social.attributes.image.data.attributes.url
                }
              />
            </a>
          );
        })}
      </div>
    </>
  );
}
