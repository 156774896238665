import styles from "@styles/Layout.module.css";

interface Props {
  children?: any;
  className?: string;
}

export default function Layout({ children, className }: Props) {
  return (
    <main className={`${styles.Layout}${className ? " " + className : ""}`}>
      {children !== undefined ? children : <></>}
    </main>
  );
}
