import Layout from "@components/Layout";
import styles from "@styles/tabs/index/VideosSection.module.css";
import { Video } from "@lib/utils";
import { useEffect, useState } from "react";

export default function VideosSection() {
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    async function fetchData() {
      const resp = await fetch(`https://api.zerio.se/bolaget/getYoutubeData`);
      const respJSON = await resp.json();

      setVideos(respJSON.data.items.splice(0, 2));
    }

    fetchData();
  }, []);

  return (
    <Layout className={styles.container}>
      <h2 className="header">Videor</h2>
      <div className={styles.videos}>
        {videos.map((video: Video, idx: number) => {
          return (
            <div
              className={styles.video}
              key={idx}
              itemScope
              itemType="https://schema.org/VideoObject"
            >
              <meta
                itemProp="thumbnail"
                content={video.snippet.thumbnails.high.url}
              />

              <iframe
                className={`${styles.videoFrame}`}
                src={"https://www.youtube.com/embed/" + video.id.videoId}
                title={video.snippet.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}
                srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${video.id.videoId}/?autoplay=1><img src="${video.snippet.thumbnails.high.url}" alt="En youtube video"><span>▶</span></a>`}
              ></iframe>
              <h3 className={styles.videoLabel}>{video.snippet.title}</h3>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
