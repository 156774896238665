import { WindowSize } from "@lib/utils";
import styles from "@styles/BackgroundVideo.module.css";

interface PropsStruct {
  size: WindowSize;
}

export default function BackgroundVideo(props: PropsStruct) {
  return (
    <video
      playsInline={true}
      autoPlay
      typeof="video/mp4"
      muted
      loop
      className={`${
        props.size.width > props.size.height
          ? styles.backgroundVideo
          : styles.phoneVideo
      } ${
        props.size.width > props.size.height
          ? props.size.width * 0.5625 > props.size.height
            ? styles.w
            : styles.h
          : props.size.height * 0.5625 > props.size.width
          ? styles.h
          : styles.w
      }`}
      src={
        props.size.width > props.size.height
          ? "/videos/background.mp4"
          : "/videos/phone_background.mp4"
      }
    ></video>
  );
}
