import { useState, useEffect } from "react";

export interface WindowSize {
  width: number;
  height: number;
}

export interface Social {
  id: number;

  attributes: {
    image: any;
    name: string;
    link: string;
  };
}

export interface Concert {
  id: number;

  attributes: {
    date: string;
    location: string;
    country: string;
    link: string;
  };
}

export interface Member {
  id: number;

  attributes: {
    name: string;
    picture: any;
  };
}

export interface Video {
  id: {
    videoId: string;
  };

  snippet: {
    thumbnails: {
      default: {
        url: string;
      };
      medium: {
        url: string;
      };
      high: {
        url: string;
      };
    };
    title: string;
  };
}

export interface Artist {
  external_urls: { spotify: string };
  href: string;
  id: string;
  name: string;
  type: string;
  uri: string;
}

export interface AlbumImage {
  height: number;
  width: number;
  url: string;
}

export interface Song {
  artists: Artist[];
  disc_number: number;
  duration_ms: number;
  explicit: boolean;
  external_urls: {
    spotify: string;
  };
  href: string;
  id: string;
  is_local: boolean;
  is_playable: boolean;
  name: string;
  preview_url: string;
  track_number: number;
  type: "track";
  uri: string;
}

export interface Album {
  album_group: "single" | "album";
  album_type: "single" | "album";
  artists: Artist[];
  external_urls: { spotify: string };
  href: string;
  id: string;
  images: AlbumImage[];
  songs: Song[];
  name: string;
  release_date: string;
  release_date_precision: string;
  total_tracks: number;
  type: string;
  uri: string;
}

export interface Tab {
  id: number;

  attributes: {
    link: string;
    paths?: string[];
    text: string;
    outsideLink?: boolean;
  };
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 1,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
