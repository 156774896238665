import BackgroundVideo from "@components/BackgroundVideo";
import styles from "@styles/tabs/index/MainSection.module.css";
import { WindowSize } from "@lib/utils";

interface Props {
  header: string;
  size: WindowSize;
}

export default function MainSection({ header, size }: Props) {
  return (
    <div className={styles.container}>
      <BackgroundVideo size={size} />
      <div
        className={styles.header}
        style={{
          backgroundImage: "url(" + header + ")",
        }}
      />
    </div>
  );
}
