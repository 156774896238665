import Head from "next/head";
import Layout from "@components/Layout";
import Navbar from "@components/Navbar";
import { fetchData } from "@lib/strapi";
import { type Social, type WindowSize, useWindowSize, Tab } from "@lib/utils";
import MainSection from "@components/tabs/index/MainSection";
import VideosSection from "@components/tabs/index/VideosSection";
import HeroSection from "@components/tabs/index/HeroSection";
import Footer from "@components/Footer";

export async function getStaticProps() {
  const data = await fetchData("/global", {
    populate: {
      title: "*",
      header: "*"
    }
  });

  const data2 = await fetchData("/socials", {
    populate: {
      link: "*",
      image: "*",
      name: "*"
    }
  });

  const data3 = await fetchData("/navigations", {
    populate: {
      text: "*",
      link: "*",
      outsideLink: "*"
    }
  });

  data3.data.sort((a: Tab, b: Tab) => a.id - b.id);

  const data4 = await fetchData("/main", {
    populate: {
      link: "*",
      text: "*",
      logo: "*",
      image: "*"
    }
  });

  return {
    props: {
      navHeader:
        process.env.NEXT_PUBLIC_STRAPI_API_URL +
        data.data.attributes.header.data.attributes.url,
      title: data.data.attributes.title,
      socials: data2.data,
      tabs: data3.data,
      heroText: data4.data.attributes.text,
      heroLink: data4.data.attributes.link,
      heroIcon:
        process.env.NEXT_PUBLIC_STRAPI_API_URL +
        data4.data.attributes.logo.data.attributes.url,
      image:
        process.env.NEXT_PUBLIC_STRAPI_API_URL +
        data4.data.attributes.image.data.attributes.url
    }
  };
}

interface PropsStruct {
  title: string;
  navHeader: string;
  socials: Social[];
  tabs: Tab[];
  image: string;
  heroText: string;
  heroLink: string;
  heroIcon: string;
}

export default function Home({
  title,
  navHeader,
  image,
  socials,
  tabs,
  heroText,
  heroLink,
  heroIcon
}: PropsStruct) {
  const size: WindowSize = useWindowSize();

  return (
    <>
      <Head>
        <title>{title + " - Start"}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <>
        <Layout>
          <Navbar
            header={navHeader}
            size={size}
            socials={socials}
            tabs={tabs}
          />

          <MainSection header={navHeader} size={size} />
          <HeroSection
            image={image}
            text={heroText}
            link={heroLink}
            icon={heroIcon}
          />
          <VideosSection />
        </Layout>
        <Footer socials={socials} tabs={tabs} />
      </>
    </>
  );
}
